import { url } from "inspector";
import {EagleApiInterface, EagleModuleApiInterface} from "kernel/api/api";

export default (api: EagleApiInterface) => {
    api.collection.providerApi = <EagleModuleApiInterface>{
        getWebsite: (id) =>
            api.request({
                url: `/api/website/provider/${id}`,
                type: "get",
            }),
        updateWebsite: (id, params = {}) =>
            api.request({
                url: `/api/website/provider/${id}`,
                type: "post",
                params
            }),
        read: (id) =>
            api.request({
                url: `/api/provider/public/${id}`,
                type: "get",
            }),
        create: (params = {}) =>
            api.request({
                url: `/api/provider`,
                type: "post",
                params,
            }),
        update: (id, params = {}) =>
            api.request({
                url: `/api/provider/${id}/update`,
                type: "put",
                params,
            }),
        getTodayProducts: (id, params = {}) =>
            api.request({
                url: `/api/provider/${id}/products/todayPrice/index`,
                type: "get",
            }),
        setTodayProductPrice: (id, params = {}) =>
            api.request({
                url: `/api/provider/${id}/products/todayPrice/update`,
                type: "put",
                params,
            }),
        getTimes: (id, params = {}) =>
            api.request({
                url: `/api/provider/${id}/getTimes`,
                type: "get",
            }),
        setTimes: (id, params = {}) =>
            api.request({
                url: `/api/provider/${id}/settingTimes`,
                type: "put",
                params,
            }),
        getOrders: (id, params = {}) =>
            api.request({
                url: `/api/order/${id}/index`,
                type: "get",
                params,
            }),
        getOrderDetail: (provider, orderId) =>
            api.request({
                url: `/api/order/${provider}/${orderId}`,
                type: "get",
            }),
        getDeliveryMans: (provider, params = {}) =>
            api.request({
                url: `/api/order/delivery-man/provider/${provider}/delivery/index`,
                type: "get",
                params,
            }),
        sortDeliveryMans: (provider, orderId, params = {}) =>
            api.request({
                url: `/api/order/${orderId}/delivery-man/provider/${provider}/delivery/update`,
                type: "put",
                params,
            }),
        getDeliveries: (provider, params = {}) =>
            api.request({
                url: `/api/order/provider/${provider}/delivery/index`,
                type: "get",
                params,
            }),
        updateDeliver: (provider, orderId, params) =>
            api.request({
                url: `/api/order/${orderId}/provider/${provider}/delivery/update`,
                type: "put",
                params,
            }),
        batchDeliverStatus: (providerId, params) =>
            api.request({
                url: `api/order/provider/${providerId}/delivery-status/batch`,
                type: "put",
                params,
            }),
        getDrivers: (provider) =>
            api.request({
                url: `/api/order/provider/${provider}/delivery-man/index`,
                type: "get",
            }),
        updateOrder: (provider, orderId, params) =>
            api.request({
                url: `/api/order/${provider}/${orderId}/update`,
                type: "put",
                params,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }),
        getPendingRefundList: (provider) => 
            api.request({
                url: `/api/payment/pendingRefund/${provider}`,
                type: "get",
            }),
        refundOrder: (paymentRecordId) => 
            api.request({
                url: `/api/payment/refund/${paymentRecordId}`,
                type: "put",
            }),
        batchUpdateOrderStatus: (
            provider,
            targets: string[],
            column: string,
            value: any
        ) =>
            api.request({
                url: `/api/order/${provider}/batch`,
                type: "patch",
                params: {
                    ids: targets,
                    column,
                    value,
                },
            }),
        getBackOrders: (provider) =>
            api.request({
                url: `/api/provider/${provider}/backOrders/index`,
                type: "get",
            }),
        getBackOrderDetail: (provider, orderId) =>
            api.request({
                url: `/api/provider/${provider}/backOrders/${orderId}/read?getReason=true`,
                type: "get",
            }),
        updateBackOrderDetail: (provider, orderId, params) =>
            api.request({
                url: `/api/provider/${provider}/backOrders/${orderId}/update`,
                type: "put",
                params,
            }),
        MessageTextUpdate: (id, params = {}) =>
            api.request({
                url: `/api/provider/${id}/MessageTextUpdate`,
                type: "put",
                params,
            }),
        validRegister: () =>
            api.request({
                url: `/api/provider/validator`,
                type: "post",
            }),
        codeValidate: (params = {}) =>
            api.request({
                url: `/api/provider/code-validate`,
                type: "post",
                params,
            }),
        invite: (providerId) =>
            api.request({
                url: `/api/provider/${providerId}/invite`,
                type: "post",
            }),
        inviteConfig: (providerId, params = {}) =>
            api.request({
                url: `/api/provider/${providerId}/invite-config`,
                type: "get",
                params,
            }),
        // 驗證邀請的共同管理員
        verifyInvite: (providerId: string, otp: string) =>
            api.request({
                url: `/api/provider/${providerId}/verify-invite`,
                type: "post",
                params: {
                    otp,
                },
            }),
        join: (providerId, params) =>
            api.request({
                url: `/api/provider/${providerId}/join`,
                type: "post",
                params,
            }),
        // 客戶管理列表
        clientIndex: (providerId, params) =>
            api.request({
                url: `/api/provider/${providerId}/store/index`,
                type: "get",
                params,
            }),
        // 取消合作
        cancel: (providerId, storeId) =>
            api.request({
                url: `/api/provider/${providerId}/store/${storeId}/delete`,
                type: "delete",
            }),
        // 接受合作/拒絕合作/重新核准
        updateStatus: (providerId, storeId, params) =>
            api.request({
                url: `/api/provider/${providerId}/store/${storeId}/pivot/update`,
                type: "put",
                params,
            }),
        // 批次 接受合作/拒絕合作/取消合作
        batchStatus: (providerId, params) =>
            api.request({
                url: `/api/provider/${providerId}/store/status/batch`,
                type: "put",
                params,
            }),
        // 變更付款方式
        changePayment: (providerId, storeId, params) =>
            api.request({
                url: `/api/provider/${providerId}/store/${storeId}/changePayment`,
                type: "patch",
                params,
            }),
        // 取得店家 VIP 列表
        readStoreVip: (providerId, params) =>
            api.request({
                url: `/api/provider/${providerId}/store-vip`,
                type: "get",
                params,
            }),
        pickingListExport: (providerId, params = {}) =>
            api.request({
                url: `/api/provider/${providerId}/picking-list/export`,
                type: "get",
                params,
            }),
        pickingListExportOrder: (providerId, orderId) =>
            api.request({
                url: `/api/provider/${providerId}/picking-list/export/${orderId}`,
                type: "get",
            }),
        pickingListExportSummary: (providerId, params = {}) =>
            api.request({
                url: `/api/provider/${providerId}/picking-list/exportSummary`,
                type: "get",
                params,
            }),
        pickingListExportAbstract: (providerId, params = {}) =>
            api.request({
                url: `/api/provider/${providerId}/picking-list/exportAbstract`,
                type: "get",
                params,
            }),
        relationLineNotifyAccessToken: (provider_id, code) =>
            api.request({
                url: `/api/line-notify/provider/${provider_id}/relation-access-token/${code}`,
                type: "post",
            }),
        getLineNotifyClientId: () =>
            api.request({
                url: `/api/line-notify/line-notify-client-id`,
                type: "get",
            }),
        getLineNotifyListByProviderId: (provider_id) =>
            api.request({
                url: `/api/line-notify/provider/${provider_id}`,
                type: "get",
            }),
        // 取得運費價格
        getFreightPrice: (provider_id) =>
            api.request({
                url: `/api/provider/${provider_id}/logistic/price`,
                type: "get",
            }),
        // 創建運費價格
        creatFreightPrice: (provider_id, params = {}) =>
            api.request({
                url: `/api/provider/${provider_id}/logistic/price`,
                type: "post",
                params,
            }),
        // 更新運費價格
        updateFreightPrice: (provider_id, params = {}, paramsId) =>
            api.request({
                url: `/api/provider/${provider_id}/logistic/price/${paramsId}`,
                type: "PUT",
                params,
            }),
        getProductImportSample: () =>
            api.request({
                // 供應商 - 批量匯入商品-下載範例檔案
                url: `/api/provider/products/sample-import-excel`,
                type: "get",
            }),
        putProductImportPhoto: (provider_id, params = {}) =>
            api.request({
                // 供應商 - 批量匯入商品-匯入商品
                url: `/api/provider/${provider_id}/products/importPhoto`,
                type: 'post',
                params
            }),
        putProductImportFile: (provider_id, params = {}) =>
            api.request({
                // 供應商 - 批量匯入商品-匯入檔案
                url: `/api/provider/${provider_id}/products/importExcel`,
                type: 'post',
                params
            }),
        getOrderAdjustment: (provider_id, order_id) =>
            api.request({
                // 獲得調整訂單紀錄
                url: `/api/order/${provider_id}/${order_id}/adjustment`,
                type: 'get'
            }),
        putOrderAdjustment: (provider_id, order_id, params = {}) =>
            api.request({
                // 調整訂單
                url: `/api/order/${provider_id}/${order_id}/adjustment`,
                type: 'put',
                params
            }),
        putLastOrderAdjustmentStatus: (provider_id, order_id, params = {}) =>
            api.request({
                // 更新最後一筆調整訂單紀錄的狀態
                url: `/api/order/${provider_id}/${order_id}/last-adjustment/status`,
                type: 'put',
                params
            }),
        operationRecord: (provider_id, start_date, end_date) =>
            api.request({
                url: `/api/provider/${provider_id}/operation-record/index?start_date=${start_date}&end_date=${end_date}`,
                type: "get",
            }),
        getPaymentSettingForLinePay: (provider_id) => 
            // 供應商取得LinePay金流設定
            api.request({
                // /api/linepay/setting/{provider}
                url: `/api/linepay/setting/${provider_id}`,
                type: "get",
            }),
        setPaymentSettingForLinePay: (provider_id, params) => 
            // 供應商更新LinePay金流設定
            api.request({
                url: `/api/linepay/setting/${provider_id}`,
                type: "post",
                params,
            }),
        getPaymentSettingForECPay: (provider_id) => 
            // 供應商取得綠界金流設定
            api.request({
                url: `/api/ecpay/setting/${provider_id}`,
                type: "get",
            }),
        setPaymentSettingForECPay: (provider_id, params) => 
            // 供應商更新綠界金流設定
            api.request({
                url: `/api/ecpay/setting/${provider_id}`,
                type: "post",
                params
            }),
        updateUnit: (product_id, params = {}) =>
            api.request({
                // 開啟/關閉出貨單位
                url: `/api/provider/${product_id}/open-shipping-unit/update`,
                type: 'put',
                params
            }),
        
    };
    api.collection.vipApi = <EagleModuleApiInterface>{
        read: (provider_id) =>
            api.request({
                // 供應商 - 取得客戶分級列表
                url: `/api/provider/${provider_id}/vipGroup`,
                type: "get",
            }),
        add: (provider_id, params = {}) =>
            api.request({
                // 供應商 - 建立客戶分級
                url: `/api/provider/${provider_id}/vipGroup`,
                type: "post",
                params,
            }),
        delete: (provider_id, group_id) =>
            api.request({
                // 供應商 - 刪除客戶分級
                url: `/api/provider/${provider_id}/vipGroup/${group_id}`,
                type: "delete",
            }),
        readStore: (provider_id, group_id) =>
            api.request({
                // 供應商 - 取得分級下客戶
                url: `/api/provider/${provider_id}/vipGroup/${group_id}/store`,
                type: "get",
            }),
        addStore: (provider_id, store_id, params = {}) =>
            api.request({
                // 供應商 - 設定客戶分級
                url: `/api/provider/${provider_id}/store/${store_id}/vipGroup`,
                type: "post",
                params,
            }),
        updateStore: (provider_id, store_id, params = {}) =>
            api.request({
                // 供應商 - 變更客戶分級
                url: `/api/provider/${provider_id}/store/${store_id}/vipGroup`,
                type: "put",
                params,
            }),
        deleteStore: (provider_id, store_id, params = {}) =>
            api.request({
                // 供應商 - 刪除分級下客戶
                url: `/api/provider/${provider_id}/store/${store_id}/vipGroup`,
                type: "delete",
                params,
            }),
        readProductPrice: (provider_id, product_id) =>
            api.request({
                // 供應商 - 取得商品分級價格
                url: `/api/provider/${provider_id}/product/${product_id}/vipRule`,
                type: "get",
            }),
        addProductPrice: (provider_id, product_id, params = {}) =>
            api.request({
                // 供應商 - 設定商品分級價格
                url: `/api/provider/${provider_id}/product/${product_id}/vipRule`,
                type: "post",
                params,
            }),
        excelImport: (provider_id, params = {}) =>
            api.request({
                // 供應商 - 匯入分級
                url: `/api/provider/${provider_id}/store/vip-group/import-excel`,
                type: 'post',
                params
            }),
        excelImportSample: (provider_id, params = {}) =>
            api.request({
                // 供應商 - 下載匯入範例
                url: `/api/provider/store/sample-import-vip-group-excel`,
                type: 'get',
                params
            }),
    };
    api.collection.publicApi = <EagleModuleApiInterface>{
        // 公開供應商頁面
        getProductIndex: (provider_id) =>
            api.request({
                url: `/api/provider/${provider_id}/public/product/index`,
                type: 'get',
            }),
        getProductDetail: (provider_id, product_id) =>
            api.request({
                url: `/api/provider/${provider_id}/public/product/${product_id}`,
                type: 'get',
            }),
        getProductMenu: (provider_id) =>
            api.request({
                url: `/api/provider/${provider_id}/public/product/menu/index`,
                type: 'get',
            }),
        getProductBanner: (provider_id) =>
            api.request({
                url: `/api/provider/${provider_id}/public/product/banner/index`,
                type: 'get',
            }),
        getProviderInfo: (provider_id) =>
            api.request({
                url: `/api/provider/public/${provider_id}`,
                type: "get",
            }),
    };
    api.collection.favoriteLogisticApi = <EagleModuleApiInterface>{
        get: (store_id = '', params = {}) =>
            api.request({
                // 取得物流商列表
                url: `/api/logistic/getMap${store_id !== '' ? '/' : ''}${store_id}`,
                type: 'get',
                params
            }),
        update: (params = {}) =>
            api.request({
                // 更新偏好物流商
                url: `/api/logistic/setFavLogistic`,
                type: 'put',
                params
            }),
    }
};
